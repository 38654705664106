<template>
  <v-row>
    <v-col cols="12">
      <v-row align="center" justify="center">
        <v-card rounded="lg" width="90%" class="px-4" :loading="skillSetCard.loading">
          <!-- Team Tab -->
          <v-tabs color="primary" grow center-active class="pt-5">
            <v-tab @click="changeTeam(team.team_id)" v-for="team in general.teams" :key="team.team_id">{{ team.name }}</v-tab>
          </v-tabs>

          <!-- Tab Content -->
          <v-card-text>
            <v-data-table :headers="skillSetCard.headers" :items="skillSetCard.items">
              <!-- Name -->
              <template v-slot:[`item.name`]="{ item }">
                {{ item.firstname + ' ' + item.lastname }}
              </template>

              <!-- Skill Set CheckBox -->
              <template v-slot:[`item.skill_1`]="{ item }">
                <v-checkbox v-model="item.skillset" :value="1" @change="updateSkill(item.user_id, item.skillset)"></v-checkbox>
              </template>
              <template v-slot:[`item.skill_2`]="{ item }">
                <v-checkbox v-model="item.skillset" :value="2" @change="updateSkill(item.user_id, item.skillset)"></v-checkbox>
              </template>
              <template v-slot:[`item.skill_3`]="{ item }">
                <v-checkbox v-model="item.skillset" :value="3" @change="updateSkill(item.user_id, item.skillset)"></v-checkbox>
              </template>
              <template v-slot:[`item.skill_4`]="{ item }">
                <v-checkbox v-model="item.skillset" :value="4" @change="updateSkill(item.user_id, item.skillset)"></v-checkbox>
              </template>
              <template v-slot:[`item.skill_5`]="{ item }">
                <v-checkbox v-model="item.skillset" :value="5" @change="updateSkill(item.user_id, item.skillset)"></v-checkbox>
              </template>
              <template v-slot:[`item.skill_6`]="{ item }">
                <v-checkbox v-model="item.skillset" :value="6" @change="updateSkill(item.user_id, item.skillset)"></v-checkbox>
              </template>
              <template v-slot:[`item.skill_7`]="{ item }">
                <v-checkbox v-model="item.skillset" :value="7" @change="updateSkill(item.user_id, item.skillset)"></v-checkbox>
              </template>
              <template v-slot:[`item.skill_8`]="{ item }">
                <v-checkbox v-model="item.skillset" :value="8" @change="updateSkill(item.user_id, item.skillset)"></v-checkbox>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'skillSetCard',
  data() {
    return {
      selected_team_id: null
    }
  },
  computed: { ...mapState(['general', 'skillSetCard']) },
  methods: {
    ...mapActions(['fetchTeamList', 'getSkillsetByTeamID', 'updateUserSkillSet']),
    changeTeam(team_id) {
      this.selected_team_id = team_id
    },
    updateSkill(user_id, skillset) {
      this.updateUserSkillSet({
        params: {
          user_id: user_id,
          service_type_id: skillset
        }
      })
    }
  },
  watch: {
    selected_team_id() {
      this.getSkillsetByTeamID(this.selected_team_id)
    }
  },
  mounted() {
    this.fetchTeamList()
    // Set Default Team ID to the first team in the team array
    let is_set = setInterval(() => {
      if (this.general.teams.length) {
        this.selected_team_id = this.general.teams[0].team_id
        clearInterval(is_set)
      }
    }, 200)
  }
}
</script>
