var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"px-4",attrs:{"rounded":"lg","width":"90%","loading":_vm.skillSetCard.loading}},[_c('v-tabs',{staticClass:"pt-5",attrs:{"color":"primary","grow":"","center-active":""}},_vm._l((_vm.general.teams),function(team){return _c('v-tab',{key:team.team_id,on:{"click":function($event){return _vm.changeTeam(team.team_id)}}},[_vm._v(_vm._s(team.name))])}),1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.skillSetCard.headers,"items":_vm.skillSetCard.items},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname + ' ' + item.lastname)+" ")]}},{key:"item.skill_1",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":1},on:{"change":function($event){return _vm.updateSkill(item.user_id, item.skillset)}},model:{value:(item.skillset),callback:function ($$v) {_vm.$set(item, "skillset", $$v)},expression:"item.skillset"}})]}},{key:"item.skill_2",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":2},on:{"change":function($event){return _vm.updateSkill(item.user_id, item.skillset)}},model:{value:(item.skillset),callback:function ($$v) {_vm.$set(item, "skillset", $$v)},expression:"item.skillset"}})]}},{key:"item.skill_3",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":3},on:{"change":function($event){return _vm.updateSkill(item.user_id, item.skillset)}},model:{value:(item.skillset),callback:function ($$v) {_vm.$set(item, "skillset", $$v)},expression:"item.skillset"}})]}},{key:"item.skill_4",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":4},on:{"change":function($event){return _vm.updateSkill(item.user_id, item.skillset)}},model:{value:(item.skillset),callback:function ($$v) {_vm.$set(item, "skillset", $$v)},expression:"item.skillset"}})]}},{key:"item.skill_5",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":5},on:{"change":function($event){return _vm.updateSkill(item.user_id, item.skillset)}},model:{value:(item.skillset),callback:function ($$v) {_vm.$set(item, "skillset", $$v)},expression:"item.skillset"}})]}},{key:"item.skill_6",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":6},on:{"change":function($event){return _vm.updateSkill(item.user_id, item.skillset)}},model:{value:(item.skillset),callback:function ($$v) {_vm.$set(item, "skillset", $$v)},expression:"item.skillset"}})]}},{key:"item.skill_7",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":7},on:{"change":function($event){return _vm.updateSkill(item.user_id, item.skillset)}},model:{value:(item.skillset),callback:function ($$v) {_vm.$set(item, "skillset", $$v)},expression:"item.skillset"}})]}},{key:"item.skill_8",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":8},on:{"change":function($event){return _vm.updateSkill(item.user_id, item.skillset)}},model:{value:(item.skillset),callback:function ($$v) {_vm.$set(item, "skillset", $$v)},expression:"item.skillset"}})]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }