<template>
  <v-container fluid>
    <!-- Title -->
    <v-row>
      <v-col cols="12">
        <v-card color="transparent" flat dark>
          <v-row justify="center">
            <v-slide-x-reverse-transition mode="out-in">
              <div key="accountInfo" v-if="card_switcher" class="text-h4 font-weight-bold" style="padding: 5vh">Account Information</div>
              <div key="skillSetting" v-else class="text-h4 font-weight-bold" style="padding: 5vh">Skill Setting</div>
            </v-slide-x-reverse-transition>
          </v-row>
          <v-row align="center" justify="center">
            <v-card width="90%" flat color="transparent">
              <v-btn style="float: right" depressed color="white" class="black--text" @click="card_switcher = !card_switcher">
                <v-icon left small>fas fa-exchange-alt</v-icon>
                Skill set Setting
              </v-btn>
            </v-card>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Body -->
    <v-slide-x-reverse-transition mode="out-in">
      <accountInfoCard v-if="card_switcher" />
      <skillSetCard v-else />
    </v-slide-x-reverse-transition>
  </v-container>
</template>

<script>
import accountInfoCard from '@/components/cards/accountInfoCard'
import skillSetCard from '@/components/cards/skillSetCard'

export default {
  name: 'Accounts',
  data: () => ({ card_switcher: true }),
  components: { accountInfoCard, skillSetCard }
}
</script>
