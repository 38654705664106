<template>
  <v-row>
    <v-col cols="12">
      <v-row align="center" justify="center">
        <v-card rounded="lg" width="90%" class="px-4" :loading="accountInfoCard.loading">
          <!-- Team Tab -->
          <v-tabs color="primary" grow center-active class="pt-5">
            <v-tab @click="changeTeam(team.team_id)" v-for="team in general.teams" :key="team.team_id">{{ team.name }}</v-tab>
          </v-tabs>

          <!-- Tab Content -->
          <v-row no-gutters justify="center" align="center">
            <v-col cols="6">
              <v-card-subtitle>Account Information</v-card-subtitle>
            </v-col>
            <v-col cols="6">
              <!-- <v-btn outlined small color="primary" style="float: right">
                <v-icon left small> mdi-plus </v-icon>
                Add New
              </v-btn> -->
            </v-col>
          </v-row>
          <v-card-text>
            <v-data-table :headers="accountInfoCard.headers" :items="accountInfoCard.items">
              <!-- Avatar -->
              <template v-slot:[`item.avatar`]="{ item }">
                <v-avatar size="38">
                  <img :src="item.avatar" v-if="item.avatar" />
                  <v-icon color="grey" dark v-else>mdi-account-circle</v-icon>
                </v-avatar>
              </template>

              <!-- Team -->
              <template v-slot:[`item.team`]="{ item }">
                <v-select @change="updateTeam(item.user_id, item.team_id)" v-model="item.team_id" dense hide-details :items="general.teams" item-text="name" item-value="team_id"></v-select>
              </template>

              <!-- Last Login -->
              <template v-slot:[`item.last_login_datetime`]="{ item }">
                <div v-if="item.last_login_datetime !== null">
                  {{ $moment(item.last_login_datetime).format('YYYY-MM-DD HH:mm:ss') }}
                </div>
              </template>

              <!-- Active -->
              <template v-slot:[`item.active`]="{ item }">
                <v-switch dense v-model="item.status" @change="updateAccountStatus({ params: { user_id: item.user_id, status: item.status ? 1 : 2 } })"></v-switch>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'accountInfoCard',
  data() {
    return {
      selected_team_id: null
    }
  },
  computed: { ...mapState(['general', 'accountInfoCard']) },
  methods: {
    ...mapActions(['fetchTeamList', 'fetchUserListByTeamID', 'updateAccountStatus', 'updateUserTeam']),
    changeTeam(team_id) {
      this.selected_team_id = team_id
    },
    // Update the team of user
    updateTeam(user_id, team_id) {
      this.updateUserTeam({
        params: {
          user_id: user_id,
          team_id: team_id
        },
        current_team: this.selected_team_id
      })
    },
    editAccount() {}
  },
  watch: {
    selected_team_id() {
      this.fetchUserListByTeamID(this.selected_team_id)
    }
  },
  mounted() {
    this.fetchTeamList()
    // Set Default Team ID to the first team in the team array
    let is_set = setInterval(() => {
      if (this.general.teams.length) {
        this.selected_team_id = this.general.teams[0].team_id
        clearInterval(is_set)
      }
    }, 200)
  }
}
</script>
